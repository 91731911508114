<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>添加角色</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">角色</a></li>
        <li>添加</li>
      </ol>
    </div>

        <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>
          <a-card title="角色信息">

            <!-- <a-divider orientation="left">个人信息</a-divider> -->


            <a-form-model-item prop="image" label="形象图">
              <van-uploader multiple :max-count="1" v-model="form.images" />
            </a-form-model-item>


            <a-form-model-item prop="background" label="背景图">
              <van-uploader multiple :max-count="1" v-model="form.backgrounds" />
            </a-form-model-item>

            <a-form-model-item prop="index" label="排序">
              <a-input type="text" placeholder="排序，从小到大" v-model="form.data.index" />
            </a-form-model-item>

            <a-form-model-item prop="name" label="名称">
              <a-input ref="name" type="text" placeholder="角色名称" v-model="form.data.name" />
            </a-form-model-item>

            <a-form-model-item prop="value" label="分值">
              <a-input type="text" placeholder="单题分值，设置后不可更改" v-model="form.data.value" />
            </a-form-model-item>
          

            <a-form-model-item prop="intro" label="简介">
              <a-textarea rows="3"  placeholder="简介" v-model="form.data.intro" />
            </a-form-model-item>

            <a-form-model-item prop="remark" label="备注">
              <a-textarea rows="2"  placeholder="备注" v-model="form.data.remark" />
            </a-form-model-item>

          </a-card>

          <div class="submit">
            <a-button type="primary" html-type="submit">确认添加</a-button>
          </div>

        </a-form-model>
  </div>
</template>

<script>
  export default {
    name: 'TestRoleCreate',
    data () {
      return {

        form: {
          layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
          },
          rules: {
            index: [{ required: true, message: '排序不可为空，从小到大' }],
            name: [{ required: true, message: '名称不可为空' }],
            value: [{ required: true, message: '单题分值不可为空' }],
          },
          images: [], backgrounds: [],
          data: {
            index: '', name: '', value: '', intro: '', remark: ''
          }
        }
      }
    },
    mounted() {
      this.$get('/test/role/create').then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
        }
      })
    },

    methods: {

      onSubmit(){
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.create();
          }
        });
      },
      create (){
        let form = new FormData();
        if (this.form.images.length) {
          form.append("image", this.form.images[0].file);
        }
        if (this.form.backgrounds.length) {
          form.append("background", this.form.backgrounds[0].file);
        }

        for (let k in this.form.data) {
          form.append(k, this.form.data[k])
        }

        this.$post('/test/role/create', form).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
  .profile {
  }
</style>
